import Swiper from 'swiper/bundle';

document.addEventListener('DOMContentLoaded', () => {
    new Swiper('.promotion-banner-swiper-container', {
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        freeMode: true,
        spaceBetween: 8,
        autoplay: true
    });

    new Swiper('.promotion-banner-wrapper--container', {
        loop: true,
        autoplay: true,
        speed: 500,
        grabCursor: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
});